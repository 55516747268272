// Dashboard.js
import React, { useState } from "react";
import { useAuth } from "../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
} from "@mui/material";

const Dashboard = () => {
  const { logout, username } = useAuth(); // Access the logged-in username from the context
  const [students, setStudents] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleFetchStudents = async () => {
    try {
      const response = await axios.get("https://jwttest.hsuk.dev/students", {
        withCredentials: true,
      });

      setStudents(response.data.data);
      setError(null);
    } catch (err) {
      if (err.response && err.response.status === 403) {
        navigate("/", { state: { errorMessage: "User not authorised" } });
      } else {
        setError("Failed to fetch students data.");
      }
      console.error("Fetch error:", err);
    }
  };

  const handleLogout = async () => {
    const success = await logout();
    if (success) {
      navigate("/");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 3,
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
      }}
    >
      <Typography variant="h4" sx={{ marginBottom: 3 }}>
        Dashboard
      </Typography>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Welcome, {username}!
      </Typography> {/* Display the logged-in username */}
      {error && <Alert severity="error">{error}</Alert>}
      <Box sx={{ display: "flex", gap: 2, marginBottom: 3 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleFetchStudents}
        >
          Fetch Students Data
        </Button>
        <Button variant="contained" color="secondary" onClick={handleLogout}>
          Logout
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ maxWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.length > 0 ? (
              students.map((student) => (
                <TableRow key={student.id}>
                  <TableCell>{student.id}</TableCell>
                  <TableCell>{student.name}</TableCell>
                  <TableCell>{student.balance.toFixed(2)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No data available. Click the button to fetch students data.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Dashboard;
