// AuthProvider.js
import React, { createContext, useContext, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [username, setUsername] = useState(""); // State to store the logged-in username
  const navigate = useNavigate();

  const login = async (username, password) => {
    try {
      const response = await fetch("https://jwttest.hsuk.dev/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
        credentials: "include",
      });

      if (response.ok) {
        setUsername(username); // Store the username on successful login
        return true;
      } else {
        console.error("Login error:", response.statusText);
        return false;
      }
    } catch (error) {
      console.error("Login error:", error);
      return false;
    }
  };

  const logout = async () => {
    try {
      const response = await fetch("https://jwttest.hsuk.dev/logoutUser", {
        method: "POST",
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          toast.success(data.data);
        }
        setUsername(""); // Clear the username on logout
        return true;
      } else if (response.status === 403) {
        navigate("/", { state: { errorMessage: "User not authorised" } });
        return false;
      } else {
        console.error("Logout error:", response.statusText);
        return false;
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        navigate("/", { state: { errorMessage: "User not authorised" } });
      } else {
        console.error("Logout error:", error);
      }
      return false;
    }
  };

  const value = {
    login,
    logout,
    username, // Provide the username in the context value
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
